import { microservice } from './customService'
import HttpService from './HttpService'

const url = microservice.getEndPointService('lecturers')
const ulrCourse = microservice.getEndPointService('course-lecture-history')
export const LecturerService = {
  getLecturerById: (lecturerId: any): Promise<any> => {
    return HttpService.axiosClient.get(`${url}/${lecturerId}`)
  },
  putLecturer: (lecturerId: any, data: any): Promise<any> => {
    return HttpService.axiosClient.put(`${url}/${lecturerId}`, data)
  },
  deleteLecturer: (data: any): Promise<any> => {
    return HttpService.axiosClient.delete(`${url}`, { data })
  },
  patchLecturer: (lecturerId: any, data: any): Promise<any> => {
    return HttpService.axiosClient.patch(`${url}/${lecturerId}`, data)
  },
  getLecturers: (data?: any): Promise<any> => {
    return HttpService.axiosClient.get(`${url}`, { params: { ...data } })
  },
  postLecturer: (data: any): Promise<any> => {
    return HttpService.axiosClient.post(`${url}`, data)
  },
  getCurrenLecturer: (): Promise<any> => {
    return HttpService.axiosClient.get(`${url}/myself`)
  },
  getLecturersName: (): Promise<any> => {
    return HttpService.axiosClient.get(`${url}-name`)
  },
  getLecturerBySearch: (search: string, data: any): Promise<any> => {
    return HttpService.axiosClient.get(`${url}`, { params: { search, ...data } })
  },
  getCourseByLectureHistory: (): Promise<any> => {
    return HttpService.axiosClient.get(ulrCourse)
  },
  postLecturerImport: (data: any): Promise<any> => {
    return HttpService.axiosClient.post(`${url}/import`, data)
  }
}
