export interface StudentScore {
  id: string,
  STT: number
  studentId: number
  studentCode: string
  studentFullname: string
  subjectId: number
  subjectCode: string
  subjectName: string
  courseId: number
  courseName: string
  progressPoint?: string
  finalGrade?: number
  examsGrade?: string
}
export interface StudentScorePayload {
  studentId: number
  courseId: number
  subjectId: number
  progressPoint: string
  grade: string
  final_grade: number
}

export interface StudentScoreResponse {
  studentId: number
  studentCode: string
  studentFullname: string
  subjectId: number
  subjectCode: string
  subjectName: string
  courseId: number
  courseName: string
  progressPoint?: any
  finalGrade?: number
  exams: {
    examId: number
    grade: string
  }[]
}

export class StudentScoreObj {
  convert(dataResponse: StudentScoreResponse[]): StudentScore[] {
    return dataResponse.map((score, index) => {
      return {
        id: `${score.studentId}_${score.subjectId}_${score.courseId}`,
        STT: index + 1,
        studentId: score.studentId,
        studentCode: score.studentCode,
        studentFullname: score.studentFullname,
        subjectName: score.subjectName,
        subjectId: score.subjectId,
        subjectCode: score.subjectCode,
        courseId: score.courseId,
        courseName: score.courseName,
        progressPoint: score.progressPoint,
        finalGrade: score.finalGrade,
        examsGrade: score.exams.map((exam) => exam.grade).join(',')
      }
    })
  }
  export(dataConvert: StudentScore[]) {
    return dataConvert.map((score) => {
      return {
        STT: score.STT,
        studentCode: score.studentCode,
        studentFullname: score.studentFullname,
        subjectName: score.subjectName,
        courseName: score.courseName,
        examsGrade: score.examsGrade,
        progressPoint: score.progressPoint,
        finalGrade: score.finalGrade
      }
    })
  }
  convertPayload(dataConvert: StudentScore): StudentScorePayload {
    return {
      studentId: dataConvert.studentId,
      courseId: dataConvert.courseId,
      subjectId: dataConvert.subjectId,
      progressPoint: dataConvert.progressPoint,
      grade: dataConvert.examsGrade,
      final_grade: dataConvert.finalGrade
    }
  }
}

export const studentScoreConstant = new StudentScoreObj()

export const StudentScoreExportHeader = [
  'STT',
  'Mã sinh viên',
  'Tên sinh viên',
  'Tên môn học',
  'Tên khoá học',
  'Điểm kiểm tra',
  'Điểm quá trình',
  'Điểm tổng kết'
]
