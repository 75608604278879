import { User } from './User'

export type Teacher = {
  id?: number | string
  lecturer_code?: string
  lecturer_birthday?: string
  lecturer_email?: string
  lecturer_fullname?: string
  lecturer_gender?: string
  lecturer_phone?: string
  lecturer_avatar?: string
  user?: User
}

export type Lecturer = {
  id?: number
  lecturer_birthday?: string
  lecturer_email?: string
  lecturer_fullname?: string
  lecturer_gender?: string | null
  lecturer_phone?: string
  lecturer_avatar?: string | null
  lecturer_level?: string
  lecturer_title?: string
  lecturer_code?: string
  courses?: {
    id?: number
    courseName?: string
    courseCreatedDate?: string
    lecturerName?: string | null
  }[]
  faculties?: { id?: number; facultyName?: string }[]
  user_id?: string
  users?: {
    id?: number
    login?: string
    user_fullName?: string
    user_email?: string
    user_code?: string
  }
  classrooms?: { id?: number; name?: string }[]
}

export type LecturerPost = {
  id?: 0
  lecturer_phone?: string
  lecturer_level?: 'Thạc sĩ' | 'Tiến sĩ' | 'Giáo sư' | 'Thính giảng' | 'Thực hành' | null
  lecturer_title?: string
  lecturer_birthday?: string
  faculty_id?: number[]
  course_id?: number[]
  classroom_id?: number[]
  user_id?: string
  user_code?: string
}

export const lecturerProfileExportHeader = [
  'STT',
  'Mã giảng viên',
  'Khoa/viện công tác',
  'Bộ môn',
  'Tên giảng viên',
  'Email',
  'Số điện thoại',
  'Ngày sinh',
  'Trình độ',
  'Chức vụ'
]

export const lecturerExportHeader = [...lecturerProfileExportHeader, 'Các lớp giảng viên giảng dạy trong kỳ']
