import { User } from './User'
import { Department } from './Department'

export type CourseStudent = {
  id: string | number
  courseStudent_notification: string
  student: Student
  point?: string | number
}

export type Student = {
  id?: string | number
  student_code?: string
  student_email?: string
  student_fullname?: string
  student_birthday?: string
  student_gender?: string
  student_phone?: string
  student_avatar?: string
  user?: User
  student_classroom?: string[]
}
export type StudentManaged = {
  id?: number
  student_code?: string
  student_birthday?: string
  student_email?: string
  student_fullname?: string
  student_gender?: string
  student_phone?: string
  student_avatar?: string
  classroom?: string
  classrooms?: { id?: number; name?: string }[]
  faculties?: { id?: number; facultyName?: string }[]
  departments?: { id?: number; department_name?: string }[]
  degree?: string
  status?: string | null
  gpa?: number | null
  academic_level?: string | null
  // user?: {
  //   id?: string
  //   userCode?: string
  //   fullName?: string
  // }
}

export type StudentPost = {
  id?: 0
  student_birthday?: string
  student_phone?: string
  classroom_id?: number | null
  faculty_id?: number | null
  department_id?: number | null
  degree?: 'Tiến sĩ' | 'Thạc sĩ' | 'Cử nhân' | null
  status?: 'Đang học' | 'Thôi học' | 'Đã tốt nghiệp' | 'Bảo lưu' | 'Đình chỉ' | null
  gpa?: number | null
  academic_level?: 'Xuất Sắc' | 'Giỏi' | 'Khá' | 'Trung Bình' | 'Yếu' | 'Kém' | null
  user_id?: string
  user_code?: string
}
export const studentProfileExportHeader = [
  'STT',
  'Mã sinh viên',
  'Tên sinh viên',
  'Ngày sinh',
  'Email',
  'Số điện thoại',
  'Khoa/Viện',
  'Chuyên ngành',
  'Hệ học',
  'Lớp',
  'Trạng thái',
  'GPA'
]

export const studentExportHeader = [...studentProfileExportHeader, 'Xếp loại']
