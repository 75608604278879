import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';

export const VideoComponent = () => {
  const slug = useParams()
  const videoSource = '/services/lmsbackend/upload/imagesForLMS/video_convert/'
  return (
    <div style={{ height: '500px' }}>
      <ReactPlayer
        controls
        url={`${videoSource}${slug.pathname}`}
        width='100%'
        height='100%'
      />
    </div>
  );
};
