import HttpService from './HttpService'
import { microservice } from './customService'

const url = microservice.getEndPointService('admin')
const urls = microservice.getEndPointService('admins')
const keycloak_url = microservice.getEndPointService('keycloak')
export const ManagerUserService = {
  getUserSystem: (option: any): Promise<any> => {
    return HttpService.axiosClient.get(`${keycloak_url}/users`, {
      params: option
    })
  },
  // searchUserSystem: (text: any, option: any): Promise<any> => {
  //   return HttpService.axiosClient.get(`${url}/UserByLecAndStudentAllField?query=${text}&page=${option.page}&size=${option.size}&sort=${option.sort}`)
  // },
  getUserDetaill: (id: any) => {
    return HttpService.axiosClient.get(`${keycloak_url}/users/${id}`)
  },
  getProfileAdmin: (): Promise<any> => {
    return HttpService.axiosClient.get(`${urls}/myself`)
  },
  import: (data): Promise<any> => {
    return HttpService.axiosClient.post(`${keycloak_url}/import`, data)
  },
  getUserByRole: (role: 'ROLE_STUDENT' | 'ROLE_LECTURER'): Promise<any> => {
    return HttpService.axiosClient.get(`${keycloak_url}/usersbyrole`, {
      params: { role }
    })
  }
}
