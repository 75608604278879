import { VideoComponent } from "@/containers/Student/VideoComponent"
import { Suspense } from "react"
import { Route, Routes } from "react-router-dom"

const AppRouterVideo = () => {
    return (
        <Suspense fallback={'loading...'}>
            <Routes>
                <Route
                    path="/video-lesson/:pathname"
                    element={
                        <Suspense>
                            <VideoComponent />
                        </Suspense>
                    }
                />
            </Routes>
        </Suspense>
    )
}
export default AppRouterVideo