export  const lmsbackend = "lmsbackend";
export  const lmstrainingmanagement = "lmstrainingmanagement";
export const lmsbackendthanhnc = "lmsquestionbank";



class microserviceApi{

    getEndPointService(endpoint:string, service?:string | null){
        if(!service) {
            service = lmsbackend;
        }
        return `/services/${service}/api/${endpoint}`
    }
    getEndPointServiceNotifycation(){
        return `/services/${lmstrainingmanagement}/ws`
    }
}

export const microservice =  new microserviceApi();